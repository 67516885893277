import React, { useEffect, useState } from 'react';

import './index.scss';
import { Redirect } from 'react-router-dom';
import { Logout, isLoggedIn, removeAnonymousToken } from '../../utils/authHelper';

const ChangePasswordStatus = (): JSX.Element => {
  const [redirectPath, setRedirectPath] = useState('/');
  const [code, setCode] = useState(false);

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let code = params.get('code');
    if (code && code.length > 1) {
      setCode(true);
    }
    waitAndRedirect(2000);
  }, []);

  const waitAndRedirect = (waittime: number) => {
    setTimeout(function () {
      if (isLoggedIn()) {
        console.log('Clearing cookies for logged-in user', 'Change Password');
        Logout();
        const tenantId = process.env.REACT_APP_B2C_TENANT_ID;
        const policy = process.env.REACT_APP_B2C_POLICY;
        if (tenantId && policy) {
          window.location.href = `https://${tenantId}.b2clogin.com/${tenantId}.onmicrosoft.com/${policy}/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`;
        } else {
          console.log('Tenant ID or policy is Undefined', tenantId, policy);
        }
      }
    }, waittime);
  };

  return (
    <div>
      <div className="changepasswordstatus">
        <h1>{code ? 'Password changed successfully!' : 'Password change cancelled'}</h1>
      </div>
      {redirectPath.length > 1 && <Redirect to={redirectPath} />}
    </div>
  );
};

export default ChangePasswordStatus;
