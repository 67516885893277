import React, { useEffect, useState } from 'react';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-react';

import './index.scss';
import { Logout } from '../../utils/authHelper';
import { Redirect } from 'react-router-dom';

type SignoutPageProps = {
  fields: {
    RichTextField: Field<string>;
    LogoutRedirectUri: Field<string>;
  };
};

const openInNewTabAndRefresh = (url: string | URL | undefined) => {
  // Open the URL in a new tab without shifting focus
  window.open(url, '_blank', 'noopener,noreferrer');
};

const SignoutPage = ({ fields }: SignoutPageProps): JSX.Element => {
  const [loggedOut, setLoggedOut] = useState(false);

  useEffect(() => {
    Logout();

    let params = new URLSearchParams(window.location.search);
    if (params.has('store')) {
      const rUrl = `${fields.LogoutRedirectUri?.value}?lob=webstore`;
      openInNewTabAndRefresh(rUrl);
      setLoggedOut(true);
      return;
    }

    let lob = params.get('lob') || '';
    lob = lob === '' ? 'webstore' : lob + ',webstore';

    const tenantId = process.env.REACT_APP_B2C_TENANT_ID;
    const policy = process.env.REACT_APP_B2C_POLICY;
    if (tenantId && policy) {
      window.location.href = `https://${tenantId}.b2clogin.com/${tenantId}.onmicrosoft.com/${policy}/oauth2/v2.0/logout?post_logout_redirect_uri=${fields.LogoutRedirectUri?.value}?lob=${lob}`;
    } else {
      window.location.href = window.location.origin;
    }
  }, []);

  return (
    <div className="signoutpage">
      {loggedOut && <Redirect to="/" />}
      <RichText field={fields.RichTextField} />
    </div>
  );
};

export default SignoutPage;
