import Cookies from 'js-cookie';
import config from '../temp/config';

const expiry = new Date(
  new Date().getTime() + 1000 * 60 * Number(process.env.REACT_APP_COOKIE_EXPIRATION_IN_MINUTES)
);

const SUB_KEY_VALIDATION_LOCK_TIME_IN_MINUTES = 30;

const subKeyValidationLockTime = new Date(
  new Date().getTime() + 1000 * 60 * SUB_KEY_VALIDATION_LOCK_TIME_IN_MINUTES
);

export const setOCToken = (accessToken: string) => {
  Cookies.set('ocToken', accessToken, { path: '/', expires: expiry });
};

export const setIdpToken = (idpToken: string) => {
  Cookies.set('idpToken', idpToken, { path: '/', expires: expiry });
};

export const setAnonymousToken = (anonToken: string) => {
  Cookies.set('anonToken', anonToken, { path: '/', expires: 7 });
};

export const setFirstTimeCookie = (firstTimeCookie: string) => {
  Cookies.set('firstTimeCookie', firstTimeCookie, { path: '/' });
};

export const removeAnonymousToken = () => {
  Cookies.remove('anonToken');
};

export const setLoggedInUserName = (loggedInUserName: string) => {
  Cookies.set('loggedInUserName', loggedInUserName, {
    path: '/',
    expires: expiry,
  });
};

export const setLoggedInUserEmail = (loggedInUserEmail: string) => {
  Cookies.set('loggedInUserEmail', loggedInUserEmail, {
    path: '/',
    expires: expiry,
  });
};

export const setBannerCookie = (cookieName: string) => {
  Cookies.set(cookieName, 'hidden', { path: '/', expires: 7 });
};

export const getToken = () => {
  return Cookies.get('ocToken');
};

export const getFirstTimeCookie = () => {
  return Cookies.get('firstTimeCookie');
};

export const getAnon = () => {
  return Cookies.get('anonToken');
};

export const getAnonymousToken = async () => {
  removeSubscriptionCookies();

  var token = Cookies.get('anonToken');
  if (token) {
    return token;
  }
  token = await getAnonTokenAPICall();
  if (token) {
    setAnonymousToken(token);
  }
  return token;
};

const getAnonTokenAPICall = () => {
  const init = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': config.subscriptionKey || '',
    },
  };
  return fetch(`${process.env.REACT_APP_CART_URL}/cartOrder/anontoken`, init)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const Logout = () => {
  Cookies.remove('ocToken');
  CleanupCookies();
};

export const CleanupCookies = () => {
  Cookies.remove('loggedInUserName');
  Cookies.remove('loggedInUserEmail');
  removeSubscriptionCookies();
};

export const getIdpToken = () => {
  return Cookies.get('idpToken');
};

export const getLoggedInUserName = () => {
  return Cookies.get('loggedInUserName');
};

export const getLoggedInUserEmail = () => {
  return Cookies.get('loggedInUserEmail');
};

export const getBannerCookie = (cookieName: string) => {
  return Cookies.get(cookieName);
};

export const isLoggedIn = () => {
  const token = getToken();
  const loggedInUserName = getLoggedInUserName();
  if (token && loggedInUserName && loggedInUserName.length > 0) {
    return true;
  }
  return false;
};

export const isTokenExists = () => {
  const anonToken = getAnon();

  if (anonToken || isLoggedIn()) {
    return true;
  }

  return false;
};

export const setIsSubscribedUserCookie = (isSubscribedUser: string) => {
  Cookies.set('isSubscribedUser', isSubscribedUser, {
    path: '/',
    expires: expiry,
  });
};

export const getIsSubscribedUserCookie = () => {
  return Cookies.get('isSubscribedUser');
};

export const setSubscriptionModeCookie = (subscriptionMode: string) => {
  Cookies.set('subscriptionMode', subscriptionMode, {
    path: '/',
    expires: expiry,
  });
};

export const getSubscriptionModeCookie = () => {
  return Cookies.get('subscriptionMode');
};

export const removeSubscriptionCookies = () => {
  Cookies.remove('isSubscribedUser');
  Cookies.remove('subscriptionMode');
};

export const isSubscribedUserCookie = () => {
  const isSubscribedUser = getIsSubscribedUserCookie();
  if (isSubscribedUser && isSubscribedUser === 'true') {
    return true;
  } else {
    return false;
  }
};

export const setSubKeyValidationFailedCount = (subKeyvalidationAttemptCount: number) => {
  Cookies.set('subKeyvalidationAttemptCount', subKeyvalidationAttemptCount.toString(), {
    path: '/',
    expires: subKeyValidationLockTime,
  });
};

export const getSubKeyValidationFailedCount = () => {
  let subKeyvalidationAttemptCount = Cookies.get('subKeyvalidationAttemptCount');
  return subKeyvalidationAttemptCount ? Number(subKeyvalidationAttemptCount) : 0;
};
export const clearSubKeyValidationFailedCount = () => {
  Cookies.remove('subKeyvalidationAttemptCount');
};
