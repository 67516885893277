import React, { useContext } from 'react';

import { IContentPageBase } from '../../model/IContentPageBase';
import './index.scss';
import { Field, LinkFieldValue } from '@sitecore-jss/sitecore-jss-react';
import LinkRenderer from '../LinkRenderer';
import { Logout } from '../../utils/authHelper';
import { renderNavMenuItems } from '../../utils/subscriptionHelper';
import { SubscriptionRoleFieldsProps } from '../Login';
import { APIWebstoreContext } from '../../context/globalContext';

export type MenuLinkFieldProps = {
  Link: Field<LinkFieldValue>;
  Id?: Field<string>;
  Roles: Array<SubscriptionRoleFieldsProps>;
};

export type MenuFieldsProps = {
  fields: MenuLinkFieldProps;
  displayName: string;
};

export type drilldownProps = {
  dataSource: IContentPageBase;
  toggleDrawer: any;
  drilldownItems: Array<MenuFieldsProps>;
};

const DrillDownMenu = ({
  dataSource,
  toggleDrawer,
  drilldownItems,
}: drilldownProps): JSX.Element => {
  const { state } = useContext(APIWebstoreContext);
  const menuItems = renderNavMenuItems(drilldownItems, state);

  return (
    <div>
      <div className="drilldown-container ">
        <div className="drilldown-title"></div>
        <div className="drilldown-body">
          <nav className="my-2 my-md-0 mr-md-3 nav-menu">
            {menuItems?.map((item: any, id: number) => {
              return (
                <LinkRenderer
                  key={id}
                  fields={{
                    Link: item.fields.Link,
                    Id: item.fields.Id,
                  }}
                  onClickEvent={() => {
                    toggleDrawer(false);
                  }}
                />
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default DrillDownMenu;
