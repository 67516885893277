import React, { useEffect, useState } from 'react';
import { Field, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';

import './index.scss';
import { getAPICall } from '../../dataFetcher/axiosMethodCalls';
import { convertLongDate } from '../../utils/helper';
import LoadingSkeleton from '../LoadingSkeleton';

export type SubscriptionDetailsPageProps = {
  fields: {
    TermsAgreementMessage: Field<string>;
    SubscriptionDetailsErrorMessage: Field<string>;
    SubscriptionContactHeading: Field<string>;
    MainHeading: Field<string>;
    SubscriptionAccountHeading: Field<string>;
    SubscriptionProductsHeading: Field<string>;
    LegalInfoHeading: Field<string>;
  };
};

export interface ISubscriptionDetails {
  subscriptionRepName: string;
  subscriptionRepEmail: string;
  accountNumber: string;
  accountName: string;
  startDate: string;
  endDate: string;
  subscribedProductGroups: Array<ISubscribedProduct>;
}

export interface ISubscribedProduct {
  productId: string;
  productName: string;
  type: string;
  products: Array<ISubscribedProduct>;
  collections: Array<string>;
  addedOn: string;
}

export enum ProductGroupTypes {
  IndustrySegment = 'IndustrySegment',
  Datasheet = 'Datasheet',
}

const SubscriptionDetailsPage = ({ fields }: SubscriptionDetailsPageProps): JSX.Element => {
  const [subscriptionInfo, setSubscriptionInfo] = useState<ISubscriptionDetails>(Object.assign({}));
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getSubscriptionInfo();
  }, []);

  const existingProductGroups = new Set<string>();

  const filteredSubscribedProductGroups = subscriptionInfo.subscribedProductGroups?.filter(
    (subscription) => {
      if (subscription.type === null) {
        return subscription.products?.length > 0 || false;
      } else {
        if (!existingProductGroups.has(subscription.productName)) {
          existingProductGroups.add(subscription.productName);
          return true;
        }
        return false;
      }
    }
  );

  function getSubscriptionInfo() {
    getAPICall(`${process.env.REACT_APP_CATALOG_URL}/catalog/subscriptiondetails`)
      .then(function (response: any) {
        if (response && response.status === 200 && response.data) {
          setSubscriptionInfo(response.data);
        } else {
          setErrorMessage(fields?.SubscriptionDetailsErrorMessage?.value);
        }
      })
      .catch((error) => {
        setErrorMessage(fields?.SubscriptionDetailsErrorMessage?.value);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getProductGroupLink(
    productName: string,
    productId: string,
    productType: string,
    collections: Array<string>
  ) {
    if (
      productType === ProductGroupTypes.IndustrySegment &&
      collections &&
      collections.length > 0
    ) {
      const encodedCollections = collections
        .map((collection) => encodeURIComponent(collection))
        .join(',');
      return (
        <Link
          to={`/subscriptions/standards#f-industrysegment=${encodedCollections}`}
          id={productId}
          target="_blank"
        >
          {productName}
        </Link>
      );
    } else if (productType === ProductGroupTypes.Datasheet) {
      return (
        <Link to="/subscriptions/standards#q=Datasheet" id={productId} target="_blank">
          {productName}
        </Link>
      );
    } else {
      return <>{productName}</>;
    }
  }

  function getSubscriptionsListSkeleton() {
    return (
      <table>
        {Array.from({ length: 3 }).map((_, index) => (
          <tr key={`tr-${index}`}>
            <td>
              <LoadingSkeleton theme="light" />
            </td>
          </tr>
        ))}
      </table>
    );
  }
  return (
    <>
      {errorMessage && errorMessage.length > 0 ? (
        <RichText field={fields.SubscriptionDetailsErrorMessage} />
      ) : (
        <>
          <div className="product-info-section">
            <div>
              <h1>{fields?.MainHeading?.value}</h1>
              <div className="main-rep-section">
                {subscriptionInfo.subscriptionRepName && subscriptionInfo.subscriptionRepEmail && (
                  <div>
                    <h3>{fields?.SubscriptionContactHeading?.value}</h3>
                    <div className="subscription-rep-details">
                      <div>
                        <span className="col-heading">Name</span>
                        <br></br>
                        <span>{subscriptionInfo.subscriptionRepName}</span>
                      </div>
                      <div>
                        <span className="col-heading">Email</span>
                        <br></br>
                        <span>{subscriptionInfo.subscriptionRepEmail}</span>
                      </div>
                    </div>
                    <hr className="horizontalLine"></hr>
                  </div>
                )}

                <div>
                  <h3>{fields?.SubscriptionAccountHeading?.value}</h3>
                  <div className="subscription-account-details">
                    <div>
                      <span className="col-heading">Account Number</span>
                      <br></br>
                      <span>
                        {isLoading ? (
                          <LoadingSkeleton theme="light" loaderHeight="20px" />
                        ) : (
                          subscriptionInfo.accountNumber
                        )}
                      </span>
                    </div>
                    <div>
                      <span className="col-heading">Account Name</span>
                      <br></br>
                      <span>
                        {isLoading ? (
                          <LoadingSkeleton theme="light" loaderHeight="20px" />
                        ) : (
                          subscriptionInfo.accountName
                        )}
                      </span>
                    </div>
                    <div>
                      <span className="col-heading">Start Date</span>
                      <br></br>
                      <span>
                        {isLoading ? (
                          <LoadingSkeleton theme="light" loaderHeight="20px" />
                        ) : (
                          convertLongDate(subscriptionInfo.startDate)
                        )}
                      </span>
                    </div>
                    <div>
                      <span className="col-heading">End Date</span>
                      <br></br>
                      <span>
                        {isLoading ? (
                          <LoadingSkeleton theme="light" loaderHeight="20px" />
                        ) : (
                          convertLongDate(subscriptionInfo.endDate)
                        )}
                      </span>
                    </div>
                  </div>
                  <hr className="horizontalLine"></hr>
                </div>

                <div>
                  <h3>{fields?.SubscriptionProductsHeading?.value}</h3>
                  {isLoading ? (
                    getSubscriptionsListSkeleton()
                  ) : (
                    <div className="products-section">
                      <ul className="product-group-list">
                        {filteredSubscribedProductGroups?.map((subscription, index) => (
                          <li
                            key={`${subscription.productId} ${index}`}
                            className={subscription.products?.length > 0 ? 'container' : ''}
                          >
                            <p>
                              {getProductGroupLink(
                                subscription.productName,
                                subscription.productId,
                                subscription.type,
                                subscription.collections
                              )}
                            </p>
                            {subscription.products?.length > 0 && (
                              <ul>
                                {subscription.products.map((item) => (
                                  <li key={item.productId}>
                                    <p>
                                      <Link
                                        to={`/subscriptions/standards/${item.productId}`}
                                        id={item.productId}
                                        target="_blank"
                                      >
                                        {item.productName}
                                      </Link>
                                      {item.addedOn && (
                                        <span className="product-available-info">
                                          {' '}
                                          (Available from {convertLongDate(item.addedOn)})
                                        </span>
                                      )}
                                    </p>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <hr className="horizontalLine product-group-margin"></hr>
                </div>

                <div>
                  <h3>{fields?.LegalInfoHeading?.value}</h3>
                  <RichText className="orderAgreementText" field={fields.TermsAgreementMessage} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SubscriptionDetailsPage;
