import React, { useContext, useMemo } from 'react';
import axios from 'axios';
import config from './temp/config';

import {
  isLoggedIn,
  getToken,
  getAnonymousToken,
  removeAnonymousToken,
  Logout,
} from './utils/authHelper';

const WithAxios = ({ props, children }) => {
  axios.interceptors.request.use(
    async (request) => {
      // add auth header with ordercloud token if account is logged in, else add an anonymous token
      if (isLoggedIn()) {
        const token = getToken();
        request.headers.common.Authorization = `Bearer ${token}`;
      } else {
        const token = await getAnonymousToken();
        request.headers.common.Authorization = `Bearer ${token}`;
      }
      request.headers.common['Ocp-Apim-Subscription-Key'] = config.subscriptionKey;
      return request;
    },
    (error) => {
      console.log(error, 'Response error');
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    async (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        if (isLoggedIn()) {
          console.log('Clearing cookies for logged-in user', 'Response 401');
          Logout();

          const tenantId = process.env.REACT_APP_B2C_TENANT_ID;
          const policy = process.env.REACT_APP_B2C_POLICY;
          if (tenantId && policy) {
            window.location.href = `https://${tenantId}.b2clogin.com/${tenantId}.onmicrosoft.com/${policy}/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`;
          } else {
            console.log('Tenant ID or policy is Undefined', tenantId, policy);
          }
        } else {
          console.log('Clearing cookies for anonymous user', 'Response 401');
          removeAnonymousToken();
          window.location.href = window.location.origin;
        }
      } else {
        console.log(error, 'Response error');
        return Promise.reject(error);
      }
    }
  );

  return children;
};

export default WithAxios;
