import React, { useEffect, useState } from 'react';
import { Field, LinkFieldValue, SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { GoVerified } from 'react-icons/go';
import { FaRegEdit } from 'react-icons/fa';
import Button from '@material-ui/core/Button';
import LockRoundedIcon from '@material-ui/icons/LockRounded';

import './index.scss';
import { getAPICall, postAPICall } from '../../dataFetcher/axiosMethodCalls';
import CustomTooltip from '../CustomTooltip';
import TaxExempt from './TaxExempt';
import Notification from './Notification';
import ChangePassword from '../ChangePassword';
import ShippingAddress from './ShippingAddress';
import ToastNotification from '../ToastNotification';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Box, Popper, TextField } from '@material-ui/core';
import { NewCompanyInfo } from './NewCompanyInfo';
import LoadingIcon from '../LoadingIcon';
import LoadingSkeleton from '../LoadingSkeleton';
import { isLoggedIn } from '../../utils/authHelper';
import { Redirect } from 'react-router-dom';

export type AccountInfoProps = {
  fields: {
    ChangePasswordLink: Field<LinkFieldValue>;
    confirmationMessage: Field<string>;
    okButtonText: Field<string>;
    cancelButtonText: Field<string>;
    'Tooltip Text': Field<string>;
    EmailTooltip: Field<string>;
    TaxExemptTooltipText: Field<string>;
    TaxExemptToastErrorMessage: Field<string>;
    TaxExemptToastMessage: Field<string>;
    ButtonText: Field<string>;
    NotificationDisclaimerText: Field<string>;
    SavedToastMessage: Field<string>;
    CompanyTooltip: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export interface ProfileDetails {
  FirstName: string;
  LastName: string;
  Email: string;
  Company: string;
  IsMember: boolean;
  IsCompanyExist: boolean;
}

export interface INewCompanyDetails {
  NewCompany: boolean;
  CompanyId: string;
  Name: string;
  City: string;
  Country: string;
  AddressLine1: string;
  AddressLine2: string;
  PostalCode: string;
  State: string;
  Phone: string;
}

const companyInitialState = {
  accountid: '',
  address1_city: '',
  address1_country: '',
  address1_line1: '',
  address1_line2: '',
  address1_postalcode: '',
  address1_stateorprovince: '',
  api_id: '',
  api_ismember: false,
  name: '',
};

const AccountInfo = ({ fields, sitecoreContext }: AccountInfoProps): JSX.Element => {
  const [openDeleteDialog, setopenDeleteDialog] = useState({ dialogOpen: false, addressId: '' });
  const [isEditMode, setIsEditMode] = useState(false);
  const [viewProfile, setViewProfile] = useState<ProfileDetails>();
  const [editProfile, setEditProfile] = useState<ProfileDetails>();

  const [companyExist, setCompanyExist] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [isAPIMember, setIsAPIMember] = useState(false);

  const [newCompanyDetails, setNewCompanyDetails] = useState<INewCompanyDetails>();
  const [selectedCompanyDetails, setSelectedCompanyDetails] = useState(companyInitialState);
  const [showToast, setShowToast] = useState(false);
  const [savedToastMessageValue, setSavedToastMessageValue] = useState<string>(
    fields.SavedToastMessage.value
  );
  const [profileNotificationMessage, setProfileNotifactionMessage] = useState(
    fields.SavedToastMessage
  );

  const [isSaving, setIsSaving] = useState(false);
  const [showNewCompany, setShowNewCompany] = useState(false);
  const [companyResults, setCompanyResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const toastRemove = () => {
    setShowToast(false);
  };

  function setProfileEditMode(mode: boolean) {
    setIsEditMode(mode);
  }

  function getProfileDetails() {
    getAPICall(`${process.env.REACT_APP_PROFILE_URL}/profile`)
      .then(function (response: any) {
        if (response && response.data) {
          setViewProfile(response.data);
          setCompanyExist(response.data.IsCompanyExist);
          setCompanyName(response.data.Company ? response.data.Company : '');
          setEditProfile(Object.assign({}, response.data));
          if (response.data.IsMember) {
            ValidateIsAPIMember(response.data);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function ValidateIsAPIMember(profile: ProfileDetails) {
    postAPICall(`${process.env.REACT_APP_PROFILE_URL}/profile/isdomainmember`, {
      email: profile.Email,
    }).then(function (response: any) {
      if (response && response.data) {
        profile.IsMember = response.data.IsMember;
        setIsAPIMember(response.data.IsMember);
        setViewProfile({ ...profile });
        setEditProfile(Object.assign({}, profile));
      }
    });
  }

  useEffect(() => {
    getProfileDetails();
  }, []);

  function handleClose() {
    setopenDeleteDialog((prevState) => ({
      ...prevState,
      dialogOpen: false,
      addressId: '',
    }));
    setEditProfile(Object.assign({}, viewProfile));

    if (viewProfile) {
      setIsAPIMember(viewProfile.IsMember);
    }

    setIsEditMode(false);
  }

  const handleOnChange = (e: any) => {
    if (editProfile) {
      editProfile.IsMember = e.target.checked;
      setIsAPIMember(e.target.checked);
    }
    setEditProfile(editProfile);
  };

  const handleSave = () => {
    if (editProfile) {
      setIsSaving(true);

      let companyRequest = null;

      if (newCompanyDetails) {
        companyRequest = {
          NewCompany: true,
          CompanyId: '',
          Name: newCompanyDetails.Name,
          City: newCompanyDetails.City,
          Country: newCompanyDetails.Country,
          AddressLine1: newCompanyDetails.AddressLine1,
          AddressLine2: newCompanyDetails.AddressLine2,
          PostalCode: newCompanyDetails.PostalCode,
          State: newCompanyDetails.State,
          Phone: newCompanyDetails.Phone,
        };
      } else if (
        selectedCompanyDetails &&
        selectedCompanyDetails.accountid &&
        selectedCompanyDetails.accountid.length > 0
      ) {
        companyRequest = {
          NewCompany: false,
          CompanyId: selectedCompanyDetails.accountid,
        };
      }

      postAPICall(`${process.env.REACT_APP_PROFILE_URL}/profile`, {
        Email: editProfile.Email,
        Company: companyRequest,
        IsMember: editProfile.IsMember,
      })
        .then(function (response: any) {
          if (response && response.data) {
            setToastNotification(savedToastMessageValue);
            setViewProfile({ ...response.data });
            setCompanyExist(response.data.IsCompanyExist);
            setCompanyName(response.data.Company ? response.data.Company : '');
            setIsAPIMember(response.data.IsMember);
            setEditProfile(Object.assign({}, response.data));
            setIsEditMode(false);
          }
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 400 && error.response.data) {
            if (error.response.data.ErrorCode === 'InvalidMember') {
              setToastNotification(error.response.data.ErrorMessage);
            }
          }
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const setToastNotification = (message: string) => {
    profileNotificationMessage.value = message;
    setProfileNotifactionMessage(profileNotificationMessage);
    setShowToast(true);
  };

  function isMemberOfAPI(isEditMode: boolean) {
    if (viewProfile?.IsMember && !isEditMode) {
      return (
        <div className="verifiedAPICompany">
          <GoVerified></GoVerified>
          <p className="profileDetailVerifiedText">Verified API Member Organization</p>
        </div>
      );
    } else {
      return (
        <div className="nonAPIMember">
          {isLoading ? (
            <LoadingSkeleton theme="light" loaderWidth="20px" minWidth="20px" />
          ) : isEditMode ? (
            <input
              name="APIMemberEditCheck"
              value="APIMemberEditCheck"
              checked={isAPIMember}
              className="APIMemberCheck"
              type="checkbox"
              onChange={handleOnChange}
            />
          ) : (
            <input
              name="APIMemberCheck"
              checked={viewProfile?.IsMember}
              disabled
              className="APIMemberCheck"
              type="checkbox"
              onChange={handleOnChange}
            />
          )}
          <span>API Member Organization</span>
          <CustomTooltip
            fields={{
              'Tooltip Text': fields['Tooltip Text'],
            }}
          />
        </div>
      );
    }
  }

  const handleCompanySearch = (query: string) => {
    postAPICall(`${process.env.REACT_APP_PROFILE_URL}/searchcompany`, {
      name: query,
    }).then(function (response: any) {
      if (response && response.data) {
        setCompanyResults(response.data);
      }
    });
  };

  useEffect(() => {
    setCompanyExist(true);
    setCompanyName(newCompanyDetails?.Name ? newCompanyDetails.Name : '');
  }, [newCompanyDetails]);

  function addCompanyCallback(newCompanyDetailsData: any) {
    setNewCompanyDetails({
      NewCompany: true,
      CompanyId: '',
      Name: newCompanyDetailsData.companyName,
      City: newCompanyDetailsData.city,
      Country: newCompanyDetailsData.country,
      AddressLine1: newCompanyDetailsData.street,
      AddressLine2: newCompanyDetailsData.unit,
      PostalCode: newCompanyDetailsData.postal,
      State: newCompanyDetailsData.state,
      Phone: newCompanyDetailsData.phone,
    });
    setSelectedCompanyDetails(companyInitialState);
  }

  const OPTIONS_LIMIT = 20;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  function getCompanyDetails(isEditMode: boolean) {
    return (
      <div className="personProfileCompany">
        <div className="company-title-div">
          <p className="profileDetailHeadings">Company</p>
          {isEditMode === true && fields?.CompanyTooltip?.value && (
            <CustomTooltip
              fields={{
                'Tooltip Text': fields?.CompanyTooltip,
              }}
            />
          )}
        </div>
        {isLoading ? (
          <LoadingSkeleton theme="light" loaderWidth="40%" loaderHeight="36px" />
        ) : isEditMode ? (
          <Autocomplete
            noOptionsText=""
            filterOptions={filterOptions}
            className="companyNameField"
            // autoComplete={true}
            ListboxProps={{
              style: {
                fontSize: '1rem',
                padding: 0,
                margin: 0,
              },
            }}
            onInputChange={(_, newInputValue) => {
              if (newInputValue.length > 2) {
                handleCompanySearch(newInputValue);
              }
            }}
            options={companyResults}
            renderOption={(option) => {
              return (
                <Box>
                  <div style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>{option.name}</div>
                  <div>
                    {option.address1_line1 &&
                      option.address1_line1 !== 'null' &&
                      `${option.address1_line1}`}
                    {option.address1_line2 &&
                      option.address1_line2 !== 'null' &&
                      `, ${option.address1_line2}`}
                  </div>
                  <div>
                    {option.address1_city &&
                      option.address1_city !== 'null' &&
                      `${option.address1_city}`}
                    {option.address1_stateorprovince &&
                      option.address1_stateorprovince !== 'null' &&
                      `, ${option.address1_stateorprovince}`}
                    {option.address1_postalcode &&
                      option.address1_postalcode !== 'null' &&
                      `, ${option.address1_postalcode}`}
                  </div>
                  {option.address1_country && option.address1_country !== 'null' && (
                    <div>{option.address1_country}</div>
                  )}
                </Box>
              );
            }}
            getOptionLabel={(option: any) => option.name || companyName || ''}
            defaultValue={companyExist ? companyName : ''}
            value={companyName || ''}
            getOptionSelected={() => true}
            blurOnSelect
            freeSolo
            onChange={(e: object, value: any) => {
              setSelectedCompanyDetails(value);
            }}
            onBlur={() => setShowNewCompany(false)}
            PopperComponent={(props) => (
              <Popper {...props}>
                <div
                  style={{
                    margin: 0,
                    padding: 0,
                    backgroundColor: 'white',
                    border: '1px solid lightgrey',
                  }}
                >
                  {props.children}
                </div>
              </Popper>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={companyExist ? companyName : ''}
                variant="outlined"
                size="small"
                required
              />
            )}
          />
        ) : (
          <input
            name="CompanyNameField"
            disabled={true}
            className="companyNameField"
            type="text"
            value={companyExist ? companyName : ''}
          />
        )}
      </div>
    );
  }

  function getProfileName(isEditMode: boolean, profiledetails?: ProfileDetails) {
    return (
      <div className="profileName">
        <p className="profileDetailHeadings">Name</p>
        {isLoading ? (
          <LoadingSkeleton theme="light" loaderWidth="40%" loaderHeight="30px" />
        ) : (
          <p className="profileDetailText">
            {profiledetails && profiledetails?.FirstName}{' '}
            {profiledetails && profiledetails?.LastName}
            {isEditMode && <LockRoundedIcon color="disabled" />}
          </p>
        )}
      </div>
    );
  }

  function getEmailDetails(isEditMode: boolean, emailAddress?: string) {
    if (isLoading) {
      return (
        <div className="personEmailDetails">
          <p className="profileDetailHeadings">Email</p>
          <p className="profileDetailText">
            <LoadingSkeleton theme="light" loaderWidth="40%" loaderHeight="30px" />
          </p>
        </div>
      );
    }

    if (emailAddress && emailAddress?.length > 0 && !isEditMode) {
      return (
        <div className="personEmailDetails">
          <p className="profileDetailHeadings">Email</p>
          <p className="profileDetailText">{emailAddress}</p>
        </div>
      );
    } else {
      return (
        <div className="personEmailDetails">
          <div className="EmailTitleSection">
            <p className="profileDetailHeadings">Email</p>
            <CustomTooltip
              fields={{
                'Tooltip Text': fields.EmailTooltip,
              }}
            />
          </div>

          <p className="profileDetailText">
            {emailAddress} <LockRoundedIcon color="disabled" />
          </p>
        </div>
      );
    }
  }

  function getProfileEditButtons(isEditMode: boolean) {
    if (!isEditMode) {
      return <></>;
    } else {
      return (
        <div className="profileButtons">
          <Button {...(!isSaving ? { onClick: handleSave } : {})} className="profileSave">
            {isSaving ? <LoadingIcon /> : 'SAVE CHANGES'}
          </Button>
          <Button onClick={handleClose} className="profileDisregard">
            DISREGARD
          </Button>
        </div>
      );
    }
  }

  function viewProfileDetails() {
    return (
      <div className="profileSection">
        <div className="profileTitleSection">
          <p>Profile Details</p>

          {!isEditMode &&
            (isLoading ? (
              <LoadingSkeleton theme="light" minWidth="50px" />
            ) : (
              <div className="EditLink">
                <FaRegEdit></FaRegEdit>
                <a href="#" onClick={() => setIsEditMode(true)}>
                  Edit
                </a>
              </div>
            ))}
        </div>

        <div className="profileDetails">
          <div className="profileAlign">
            <div>{getProfileName(isEditMode, viewProfile)}</div>
            <div>{getEmailDetails(isEditMode, viewProfile?.Email)}</div>
          </div>
          <div className="profileCompany">
            {getCompanyDetails(isEditMode)}
            <NewCompanyInfo
              fields={{
                SavedToastMessage: fields.SavedToastMessage,
                ButtonText: fields.ButtonText,
              }}
              showPopup={showNewCompany}
              callback={addCompanyCallback}
            ></NewCompanyInfo>
            <div className="profileVerifiedCompany">{isMemberOfAPI(isEditMode)}</div>
          </div>

          {getProfileEditButtons(isEditMode)}
        </div>
      </div>
    );
  }

  function viewTaxExemptDetails() {
    return (
      <TaxExempt
        fields={{
          TaxExemptTooltipText: fields.TaxExemptTooltipText,
          TaxExemptToastErrorMessage: fields.TaxExemptToastErrorMessage,
          ButtonText: fields.ButtonText,
          TaxExemptToastMessage: fields.TaxExemptToastMessage,
        }}
      />
    );
  }

  return (
    <div className="accountInfo">
      <div className="accountInfoHeader">
        <h1>Account Info</h1>
      </div>
      {!isLoggedIn() ? (
        <div className="accountInfoDetails">
          <div className="profileSection">
            <div className="profileTitleSection">
              <p>Session logged out. Redirecting to Home page...</p>
              <br />
              <br />
              <Redirect to="/" />
            </div>
          </div>
        </div>
      ) : (
        <div className="accountInfoDetails">
          {viewProfileDetails()}
          <br />
          <hr className="horizontalLine"></hr>
          {companyExist && (
            <>
              {viewTaxExemptDetails()}
              <hr className="horizontalLine"></hr>
            </>
          )}
          <ChangePassword fields={{ Link: fields.ChangePasswordLink }} />
          <br />
          <hr className="horizontalLine" />
          <ShippingAddress
            fields={{
              confirmationMessage: fields.confirmationMessage,
              okButtonText: fields.okButtonText,
              cancelButtonText: fields.cancelButtonText,
              SavedToastMessage: fields.SavedToastMessage,
              ButtonText: fields.ButtonText,
            }}
          ></ShippingAddress>
          <hr className="horizontalLine"></hr>
          <Notification
            fields={{
              NotificationDisclaimerText: fields.NotificationDisclaimerText,
              SavedToastMessage: fields.SavedToastMessage,
              ButtonText: fields.ButtonText,
            }}
          ></Notification>
          <ToastNotification
            fields={{
              ToastMessage: profileNotificationMessage,
              ButtonText: fields.ButtonText,
            }}
            showToast={showToast}
            removeToast={toastRemove}
          ></ToastNotification>
        </div>
      )}
    </div>
  );
};

export default AccountInfo;
